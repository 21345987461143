import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

import HeaderSigned from "../Components/HeaderSigned";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchInput from "../Components/SearchInput";
import { support1, support2, support3, support4 } from "../constant/imagePath";
import { imageUrl, URL } from "../Config/apiUrl";
import { Get, Post } from "../Axios/AxiosFunctions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BrowserView, MobileView } from "react-device-detect";
import MobileRestrictedComponent from "../Components/MobileRestrictedComponent";

const Support = () => {
  const [question, setQuestion] = useState({
    questionPost: "",
  });
  const refInput = useRef();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.authReducer?.access_token);
  console.log("support Token", token);
  console.log("namessss", question);

  const [faqQuestion, SetFaqQuestion] = useState([]);

  const array = [
    {
      icon: support1,
      title: "Short question about eGFR here?",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sodales, nisl a interdum commodo, felis quam pulvinar diam, eget molestie purus erat eget enim. Cras vehicula dui nec massa maximus, in placerat sapien vehicula. Nulla consequat risus quis placerat consequat. Quisque condimentum neque mi. Vestibulum nec posuere mi, ac ultricies felis. Morbi sit amet varius erat, tristique lobortis arcu. Donec placerat leo dui, sollicitudin tincidunt nibh ullamcorper vel. Integer cursus mi et nisi sollicitudin elementum. Praesent sed dignissim augue. Sed finibus luctus est, in maximus ex elementum ac. Morbi mattis, neque at. ",
    },
    {
      icon: support2,
      title: "A short question about cardiovascular system?",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sodales, nisl a interdum commodo, felis quam pulvinar diam, eget molestie purus erat eget enim. Cras vehicula dui nec massa maximus, in placerat Praesent sed dignissim augue. Sed finibus luctus est, in maximus ex elementum ac. Morbi mattis, neque at dapibus tristique, elit nunc eleifend dolor, ut tincidunt leo lectus a neque. Praesent pellentesque orci vitae  ",
    },
    {
      icon: support3,
      title: "A question about COPD?",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sodales, nisl a interdum commodo, felis quam pulvinar diam, eget molestie purus erat eget enim. Cras vehicula dui nec massa maximus, in placerat sapien vehicula. Nulla consequat risus quis placerat consequat. Quisque condimentum neque mi. Vestibulum nec posuere mi, ac ultricies felis. Morbi sit amet varius erat, tristique lobortis arcu. Donec placerat leo dui, sollicitudin tincidunt nibh ullamcorper vel. Integer cursus mi et nisi sollicitudin elementum. Praesent sed dignissim augue. Sed finibus luctus est, in maximus ex elementum ac. Morbi mattis, neque at. ",
    },
    {
      icon: support4,
      title: "Something about Parkinson’s?",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sodales, nisl a interdum commodo, felis quam pulvinar diam, eget molestie purus erat eget enim. Cras vehicula dui nec massa maximus, in placerat sapien vehicula. Nulla consequat risus quis placerat consequat. Quisque condimentum neque mi. Vestibulum nec posuere mi, ac ultricies felis. Morbi sit amet varius erat, tristique lobortis arcu. Donec placerat leo dui, sollicitudin tincidunt nibh ullamcorper vel. Integer cursus mi et nisi sollicitudin elementum. Praesent sed dignissim augue. Sed finibus luctus est, in maximus ex elementum ac. Morbi mattis, neque at dapibus tristique, elit nunc eleifend dolor, ut tincidunt leo lectus a neque. Praesent pellentesque orci vitae enim molestie, et commodo orci tincidunt. Mauris molestie nisi ac risus molestie accumsan. Quisque eu tortor ultrices leo tempus tempor. Ut eget lacus vitae tellus porta convallis. Nulla consequat ante lectus, vel elementum tortor rutrum at. ",
    },
  ];

  const faqResponse = URL(`cms/get_all_Question_for_User`);

  const getFaqData = async () => {
    const response = await Get(faqResponse, token, true, dispatch);
    console.log("======================>>>>>>>>>>>>>>>>>>>>>>>", response);
    if (response !== undefined) {
      SetFaqQuestion(response?.data?.data);
    }
  };

  useEffect(() => {
    getFaqData();
  }, []);
  const postQuestion = URL(`admin/add_faq_question`);
  const header = {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  console.log(question);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(question);
    const data = {
      // question: question.postQuestion,
      question: refInput.current.value,
      role: "user",
    };
    const response = await Post(postQuestion, data, header);
    if (response !== undefined) {
      toast.success("FAQ has been Submitted");
      refInput.current.value = "";
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setQuestion({ [name]: value });
  };

  return (
    <>
      <BrowserView>
        <>
          <HeaderSigned hide={true} />

          <Container fluid>
            <Row>
              <Col md={9} className="faq_heading mar-t-cus">
                <div className="mt-4 mb-4">
                  {/* <h4>Frequently Asked Questions</h4> */}
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <Row>
              <Col md={9}>
                {faqQuestion.map((item) => {
                  return (
                    <div className="questions_main mb-5">
                      <div className="questions_pic_div">
                        <Image
                          // src={item.icon}

                          src={`${imageUrl}${item?.photo}`}
                        />
                      </div>

                      <div className="questions_div">
                        <p className="bold">{item.question}</p>
                        <p className="mini_p fade_font">{item.answer}</p>
                      </div>
                    </div>
                  );
                })}
              </Col>
              <Col md={3}>
                <div className="mb-4">
                  <div className="new_search">
                    <SearchInput
                      placeholderColor={"#fff"}
                      placholderText={"Search FAQs"}
                    />
                  </div>
                </div>
                <div className="blue_ask">
                  <p>Ask us anything!</p>
                  <p>
                    Use the form below to submit any questions you may have. We
                    will answer you and also publish it on the FAQs for others
                    to benefit. Not to worry - any personal information will be
                    redacted.
                  </p>
                  <p>We can also be contacted via:</p>
                  <p>
                    <a className="whito" href="mailto:support@preregmaster.com">
                      Email
                      <br />
                      support@preregmaster.com
                    </a>
                  </p>
                  <p>
                    <a
                      className="whito"
                      target="_blank"
                      href="https://www.instagram.com/preregmaster"
                    >
                      Instagram
                      <br />
                      @PreRegMaster
                    </a>
                  </p>
                  <p className="p_very_small text-center">
                    - Our average response time is 4 hours -
                  </p>
                </div>
                <form>
                  <div className="support_textarea">
                    <TextareaAutosize
                      maxRows={4}
                      aria-label="maximum height"
                      placeholder="Type Here...."
                      style={{ width: 200 }}
                      name="questionPost"
                      onChange={handleChange}
                      ref={refInput}
                    />
                    <div
                      className="right_chevron"
                      onClick={handleSubmit}
                      style={{ cursor: "pointer" }}
                    >
                      <ChevronRightIcon />
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </Container>
        </>
      </BrowserView>
      <MobileView>
        <MobileRestrictedComponent />
      </MobileView>
    </>
  );
};

export default Support;
