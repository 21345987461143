import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Post } from "../Axios/AxiosFunctions";
import { URL } from "../Config/apiUrl";
import {
  logoNew,
  mobilePreviewAnimation,
  noMobilePreviewAnimation,
} from "../constant/imagePath";
import { saveLoginUserData } from "../store/Actions/authAction";
import { useSelector } from "react-redux";
import SubFooter from "../Components/SubFooter";
import ForgotPasswordModal from "../Components/ForgotPasswordModal";

import { BrowserView, MobileView } from "react-device-detect";
import lottie from "lottie-web";

const Signin = (params) => {
  const [responsible, setResponsible] = React.useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState(false);

  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const token = useSelector((state) => state);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };
  const signinResponse = URL(`users/login`);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await Post(signinResponse, inputs);

    if (response !== undefined) {
      // console.log({ resposnse: response?.data?.data?.user?.isSubscriptionOn });
      setResponsible(response?.data?.data);
      dispatch(saveLoginUserData(response?.data));
      // if (response?.data?.data?.user?.isSubscriptionOn == true) {
      //   history.push("/dashboard");
      // } else {
      //   history.push("/packages");
      // }
        history.push("/dashboard");

    }
    setLoading(false);
  };

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#react-logo"),
      animationData: noMobilePreviewAnimation,
    });
  }, []);

  return (
    <>
      {/* <BrowserView> */}
      <div className="pre-reg">
        <Container className="container">
          <Row className="row style-h">
            <Col md={12} className="logo text-center">
              <img src={logoNew} alt="logo" style={{ width: "80px" }} />
            </Col>
            <Col md={12} className="web-name text-center mb-4">
              <h2>PRE REG MASTER</h2>
            </Col>
            <Row md={12} className="signin-container">
              <Col md={12}>
                <h2>Login to your account</h2>
              </Col>
              <form onSubmit={handleSubmit}>
                <Col md={12} className="input input_styling">
                  <div>
                    <input
                      className="mb-3"
                      type="email"
                      name="email"
                      id="username"
                      placeholder="Email Address"
                      value={inputs.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <input
                      className="mb-3"
                      type="password"
                      name="password"
                      id="username"
                      placeholder="Password"
                      value={inputs.password}
                      onChange={handleChange}
                      autocomplete="off"
                    />
                  </div>
                  <p
                    className="c-p"
                    onClick={() => setIsForgotPasswordModalOpen(true)}
                  >
                    Forgot Password?
                  </p>
                </Col>

                <Col md={12} className="signin-btn">
                  <button type="submit" className="btn" disabled={loading}>
                    {loading ? "loading..." : "Login"}
                  </button>
                </Col>
              </form>
            </Row>
            {/* <Col md={12} className="privacy-policy">
              <p>T&Cs . Privacy Policy</p>
            </Col>
          */}
          </Row>
          <section className="question-detail-footer-custom-container">
            <SubFooter />
          </section>
        </Container>
      </div>
      {/* </BrowserView> */}
      {/* <MobileView>
        <div className="loginMobileViewContainer">
          <div id="react-logo" style={{ width: "100%" }} />
          <p>
            For the best learning experience, please access Pre Reg Master on a
            desktop device
          </p>
        </div>
      </MobileView> */}

      <ForgotPasswordModal
        show={isForgotPasswordModalOpen}
        setShow={setIsForgotPasswordModalOpen}
      />
    </>
  );
};

export default Signin;
