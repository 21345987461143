import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  return (
    <>
      <Container fluid className="footer_bg">
        <Row>
          <Col md={4}>
            <p className="mail_p">
              <a href="mailto:support@preregmaster.com">
                support@preregmaster.com
              </a>
            </p>
          </Col>
          <Col md={4}>
            <p className="mail_p">
              <a href="http://instagram.com/preregmaster">
                Instagram @PreRegMaster
              </a>
            </p>
          </Col>
          <Col md={4}>
            <p>© Pre Reg Master Ltd</p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Footer;
